<template>
  <div>
    <br v-if="isBr" />
    <span v-if="label && menuLists.length > 0">{{ $t(label) }}</span>
    <!-- menuLists--{{menuLists}} -->
    <li v-for="(item, key) in menuLists" :key="`${label}_${key}`" :class="{
      active: getActive(item)
    }">
      <!-- <router-link :to="{
        name: 'AboutInfo',
        params: {
          classification: $route.params.classification
          articleId:
        }
      }"> -->

      <!-- <router-link :to="`/${getUrlLangCode}${item.path.path}?sub=${item.path.query.sub}`">
        <p class="small">
          {{item.title}}
        </p>
      </router-link> -->
      <router-link :to="`/${item.path}`" v-if="$route.name === 'Staff'">
        <p class="small">
          {{ item.title }}
        </p>
      </router-link>

      <router-link :to="`/${getUrlLangCode()}${item.path}`" v-else-if="$route.name === 'News'">
        <p class="small">
          {{ item.title }}
        </p>
      </router-link>

      <router-link :to="item.path" v-else-if="$route.name === 'MemberHome' || $route.name === 'MemberData'">
        <p class="small">
          {{ item.title }}
        </p>
      </router-link>

      <router-link :to="`/${getUrlLangCode()}${item.path.path}?sub=${item.path.query.sub}`" v-else>
        <p class="small">
          {{ item.title }}
        </p>
      </router-link>
    </li>
  </div>
</template>

<script>
import { isString } from 'lodash';
import { getUrlLangCode } from '@/utils/getLangCode';

export default {
  name: 'MenuClassificationItem',
  props: {
    isBr: Boolean,
    menuLists: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      getUrlLangCode
    };
  },
  methods: {
    getActive(item) {
      const { path, name, params } = this.$route;
      const { articleId = '' } = params || {};
      // console.log('item', item);
      // console.log(path);
      // console.log(name);
      // console.log(params);
      // console.log(articleId);
      // console.log(item.slug === articleId);
      if (articleId === item?.slug) {
        return true;
      }
      const base = new RegExp(`^${path}(\\?.*){0,}$`);
      const testOriUrl = isString(item?.path ?? '') ? item?.path ?? '' : item?.path?.path ?? '';
      const testUrl = testOriUrl?.replace(/\/{0,}(.*)/, '/$1');
      if (encodeURI(testUrl).match(base)) {
        return true;
      }
      if (testUrl.match(base)) {
        return true;
      }
      return name === 'News' && params.tagSlug === item.path.split('/')[2];
    }
  }
};
</script>
