<template>
  <div class="menu_classification_filter_outer">
    <div class="menu_classification_filter_toggle" :class="{ active: openMenu }" @click="openMenu = !openMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="19.72" height="10.86" viewBox="0 0 19.72 10.86">
        <g transform="translate(1.414 9.86) rotate(-90)">
          <path d="M-445.412,268.363l-8.446-8.446,8.446-8.446" transform="translate(453.858 -251.471)" fill="none"
            stroke="#af566d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
      </svg>

    </div>

    <ul class="menu_classification_filter" :class="{ active: openMenu }">
      <!-- ivf/tcm/labs 單頁 -->

      <MenuClassificationItem v-for="({ label, lists }, l) in menuLists" :isBr="l > 0" :key="`${label}_${l}`"
        :label="label" :menuLists="lists" />

    </ul>
  </div>
</template>

<script>
import { getUrlLangCode } from '@/utils/getLangCode';
import MenuClassificationItem from './MenuClassificationItem.vue';

export default {
  name: 'MenuClassificationFilter',
  components: { MenuClassificationItem },
  // props: [
  //   'Classification',
  //   'ClassificationTcm',
  //   'ClassificationLabs',
  //   'ClassificationNewProcreation',
  //   'NewsByTagData',
  //   'tagSlug'
  // ],
  props: {
    menuLists: {
      type: Array,
      default: null
    }
    // type: {
    //   type: Object,
    //   default: null
    // }
  },
  data() {
    return {
      openMenu: false,
      isStaff: false,
      getUrlLangCode,
    };
  },
  created() {
    if (this.$route.name === 'Staff') {
      this.isStaff = true;
    }
  },
  methods: {
    // 此組件為共用組件，但傳進來的 path 規則不同
    // 若沒帶語言，帶上去
    setLink(url) {
      const splitUrl = url.split('/');
      if (!(['zh-TW', 'zh-CN', 'en']).includes(splitUrl[0])) {
        return `/${getUrlLangCode()}${url}`;
      }

      return `/${url}`;
    },
  },
  watch: {
    $route(/* to, from */) {
      this.openMenu = false;
    }
  }
};
</script>
